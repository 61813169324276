var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Name"},model:{value:(_vm.template.name),callback:function ($$v) {_vm.$set(_vm.template, "name", $$v)},expression:"template.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Subject"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Subject","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Subject"},model:{value:(_vm.template.subject),callback:function ($$v) {_vm.$set(_vm.template, "subject", $$v)},expression:"template.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Body English"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Body","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('rich-editor',{attrs:{"id":"template.bodyEn"},model:{value:(_vm.template.bodyEn),callback:function ($$v) {_vm.$set(_vm.template, "bodyEn", $$v)},expression:"template.bodyEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Body Arabic"}},[_c('validation-provider',{attrs:{"name":"Body","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('rich-editor',{attrs:{"id":"template.bodyAr"},model:{value:(_vm.template.bodyAr),callback:function ($$v) {_vm.$set(_vm.template, "bodyAr", $$v)},expression:"template.bodyAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Email From"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Email From","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.emailOptions,"state":errors.length ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select an email")])]},proxy:true}],null,true),model:{value:(_vm.template.emailFrom),callback:function ($$v) {_vm.$set(_vm.template, "emailFrom", $$v)},expression:"template.emailFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('span',[_vm._v("Save")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }