<template>
    <validation-observer ref="validationObserver">
        <b-card-form>
            <!-- First Name and Last Name -->
            <b-row>
                <b-col md="6" xl="6" class="mb-1">
                    <b-form-group label="Name">
                        <validation-provider #default="{ errors }" rules="required" name="Name" mode="passive">
                            <b-form-input v-model="template.name" :state="errors.length ? false : null"
                                placeholder="Name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="6" xl="6" class="mb-1">
                    <b-form-group label="Subject">
                        <validation-provider #default="{ errors }" rules="required" name="Subject" mode="passive">
                            <b-form-input v-model="template.subject" :state="errors.length ? false : null"
                                placeholder="Subject" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <!-- Arabic First Name and Last Name -->
            <b-row>
                <b-col md="12" xl="12" class="mb-1">

                    <b-form-group label="Body English">
                        <validation-provider #default="{ errors }" rules="required" name="Body" mode="passive">

                            <rich-editor v-model="template.bodyEn" id="template.bodyEn"></rich-editor>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12" xl="12" class="mb-1">

                    <b-form-group label="Body Arabic">
                        <validation-provider #default="{ errors }"  name="Body" mode="passive">

                            <rich-editor v-model="template.bodyAr" id="template.bodyAr"></rich-editor>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6" xl="6" class="mb-1">
                    <b-form-group label="Email From">
                        <validation-provider #default="{ errors }" rules="required" name="Email From" mode="passive">
                            <b-form-select v-model="template.emailFrom" :options="emailOptions"
                                :state="errors.length ? false : null">
                                <template #first>
                                    <option :value="null" disabled>Select an email</option>
                                </template>
                            </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="text-right">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="save">
                        <span>Save</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-form>
    </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
    BFormInput, BRow, BCol, BFormGroup, BButton
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import richEditor from '../../components/ckEditor/richEditor.vue'

import { BFormSelect } from 'bootstrap-vue';

// Extend VeeValidate with a custom rule for Arabic text validation
extend('arabicOnly', {
    validate(value) {
        // Regular expression to match Arabic characters
        const arabicRegex = /^[\u0600-\u06FF\s]+$/;
        return arabicRegex.test(value);
    },
    message: 'The {_field_} field must contain only Arabic characters.'
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCardForm,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BButton,
        richEditor,
        vSelect,
        BFormSelect
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            template: {

                name: null,
                bodyEn: '',
                bodyAr: '',
                emailFrom: null,
                subject: null,


            },
            emailOptions: [
                { value: 'info@bainkom.com', text: 'info@bainkom.com' },
                { value: 'rawan@bainkom.com', text: 'rawan@bainkom.com' },
                { value: 'araz@bainkom.com', text: 'araz@bainkom.com' },
            ],

        }
    },

    methods: {
        async save() {
            const isValid = await this.$refs.validationObserver.validate();

            // Validation Failed
            if (!isValid) {
                return this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Check fields validation!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }

            // Validation Passed
            try {
                await this.$http.post('EmailTemplates', this.template);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'template has been added',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    },
                });

                this.$router.push({ name: 'templates' });
            } catch (error) {
            }
        },

    },


}
</script>